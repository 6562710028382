body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee !important;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", Poppins;
}

.leaflet-container {
  height: 100vh;
}

#mapId {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

.Button {
  border-radius: 30px;
}

.MuiButton-root {
  font-family: "Poppins" !important;
  text-transform: capitalize;
}

.MuiFormLabel-root,
.MuiInputLabel-root {
  font-family: "Poppins" !important;
  color: #000 !important;
  font-size: 0.938rem !important;
}

.inputRounded {
  font-family: "Poppins";
  color: "primary";
}

.MuiTypography-h5 {
  text-transform: uppercase;
  font-size: 1.2rem !important;
  font-weight: 900 !important;
}

.MuiInputBase-root,
.MuiOutlinedInput-root {
  font-family: "Poppins" !important;
  color: #000 !important;
  font-size: 0.938rem !important;
  border-radius: default !important;
  background-color: #fff !important;
  border-color: #34006a !important;
  border-width: 3px !important;
}

.MuiInputBase-input,
.MuiOutlinedInput-input {
  background-color: #fff !important;
  border-radius: inherit !important;
}

.MuiButton-contained {
  color: #000 !important;
  text-transform: uppercase !important;
  font-weight: 900 !important;
  font-family: "Roboto Condensed" !important;
  background-color: #f0ecf8 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.Mui-focused {
  color: #000;
}

.MuiMenuItem-root,
.RaMenuItemLink-root {
  font-size: 0.875rem !important;

  font-family: "Poppins" !important;
}

.MuiDrawer-root {
  background-color: transparent !important;
}

.MuiButtonGroup-grouped,
.MuiButtonGroup-grouped:not(:first-of-type),
.MuiButtonGroup-grouped:not(:last-of-type) {
  border-radius: 0px !important;
}

.MuiButtonGroup-grouped:first-of-type {
  border-radius: 10px 0 0 10px !important;
  color: #000;
}

.MuiButtonGroup-grouped:last-of-type {
  border-radius: 0 10px 10px 0 !important;
}

.MuiButton-root {
  border-radius: 30px !important;
}

.MuiButtonBase-root,
.MuiButton-root,
.MuiButtonGroup-root,
.MuiButtonGroup-grouped:first-of-type,
.MuiButtonGroup-grouped:not(:first-of-type) {
  text-transform: capitalize;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.css-k0f6ya-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type),
.css-hxky5c-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type),
.css-liqdll .MuiButtonGroup-grouped:not(:last-of-type),
.css-3sd5u .MuiButtonGroup-grouped:not(:last-of-type) {
  border-right: 0 !important;
}

/* h4 {
  font-weight: 900 !important;
  font-family: 'Roboto Condensed' !important;

  letter-spacing: normal;
}


h6 {
  font-weight: 900 !important;
  font-family: 'Roboto Condensed' !important;

  letter-spacing: normal;
} */

.MuiDrawer-docked-RaSidebar-root,
.RaSidebar-fixed {
  background-color: #fff !important;
  height: 96% !important;
  border-radius: 20px !important;
}
.css-dth88-MuiDrawer-docked-RaSidebar-root,
.css-m6x6at {
  margin-right: 30px !important;
  margin-left: 30px !important;
  margin-top: -33px !important;
}

.MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiToolbar-root {
  padding-left: 16px !important;
}

text {
  font-family: "Poppins" !important;
}

.MuiCard-root,
.MuiPaper-root {
  border-radius: 0px !important;
}

.RaDatagrid-headerCell {
  background-color: #ffa92b !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  text-align: center !important;
}

.MuiTableCell-root {
  font-family: "Poppins" !important;
  text-align: center !important;
}

.MuiLink-root {
  font-family: "Poppins" !important;
}

.MuiPaginationItem-root {
  font-family: "Poppins" !important;
}

.RaDatagrid-headerCell:hover {
  /* background-color: #34006A !important; */
  color: #000 !important;
}

.MuiTableSortLabel-root.Mui-selected:hover {
  color: #000 !important;
}

.MuiTableSortLabel-root:hover {
  color: #000 !important;
}

.Mui-selected:hover,
.Mui-selected {
  background-color: #db8b12 !important;
}

.MuiButtonBase-root,
.MuiButton-root,
.MuiButtonGroup-root,
.MuiButtonGroup-grouped:first-of-type,
.MuiButtonGroup-grouped:not(:first-of-type) {
  text-transform: capitalize;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none !important;
}

.MuiButtonGroup-root,
.MuiButtonGroup-grouped {
  text-transform: capitalize !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
}

.RaLabeled-label {
  font-family: "Poppins" !important;
  font-size: 15px !important;
  color: #34006a !important;
}

p {
  font-family: "Poppins" !important;
}

.ra.message.delete_title,
.MuiDialogTitle-root {
  color: #000 !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  border-bottom: #34006a 1px !important;
}

.MuiDialogContentText-root {
  font-size: 15px !important;
}

.MuiTableSortLabel-root.Mui-active {
  color: #fff !important;
}

.RaMenuItemLink-active {
  color: #db8b12 !important;
}

.MuiTableSortLabel-icon {
  color: #db8b12 !important;
}
.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.Mui-selected {
  color: #db8b12 !important;
  background-color: #fff !important;
}

.record-with-community-switch .MuiSwitch-thumb {
  background-color: rgb(52, 0, 106);
}
.record-with-community-switch .MuiSwitch-track {
  background-color: rgb(52, 0, 106) !important;
  opacity: 0.5;
}

.css-1d6wzja-MuiButton-startIcon > *:nth-of-type(1) {
  font-size: 20px;
  margin-left: 6px !important;
}
.css-6xugel > :nth-of-type(1) {
  font-size: 20px;
  margin-left: 6px !important;
}

.css-19bpcm7-MuiGrid-root,
.css-tky9jk {
  display: flex;
}

.btnLogin {
  border-radius: 14px !important;
  display: block !important;
  box-shadow: 0px 6px 30px #ffa01485 !important;
  background-color: #ffa114 !important;
  opacity: 1 !important;
  color: #fff !important;
  height: 50px !important;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  border-color: transparent !important;
  cursor: pointer !important;
}

.saveBtn {
  background: #ffa014 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 10px 30px #ffa01470 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  width: 178px !important;
  height: 57px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  color: #fff !important;
  cursor: pointer !important;
}
/* .saveBtn .MuiSvgIcon-root {
  display: none !important;
} */
.btnCancel {
  width: 178px;
  height: 57px;
  /* box-shadow: 0px 10px 30px #FFA01470 !important; */
  border: 1px solid #f82323 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-transform: capitalize !important;
  font-weight: 900;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  /* margin-top: 20px !important; */
  /* background-color: #F8F8F8 !important; */
  /* height: 90vh; */
}
.typographytitle {
  color: #010101 !important;
  /* color: #34006A !important; */
  text-transform: capitalize !important ;
  font-size: 22px !important;
  font-weight: 700 !important;
}

.css-1q2ae6k-MuiToolbar-root-RaTopToolbar-root
  .css-nl0uiq-MuiButtonBase-root-MuiButton-root-RaButton-root.MuiButton-sizeSmall,
.css-17tmrxl .css-vz9mn9 {
  color: #fff !important;
  background: #ffa014 !important;
  box-shadow: 0px 10px 30px #ffa0145e !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 10px 15px !important;
  margin-bottom: 8px !important;
  text-transform: capitalize !important;
}

.css-nl0uiq-MuiButtonBase-root-MuiButton-root-RaButton-root:hover,
.css-vz9mn9:hover {
  background-color: none !important;
  text-decoration: none !important;
}

.roleStyle {
  font-size: 13px !important;
  font-weight: 500 !important;
  text-align: center !important;
  background-color: hsl(36, 100%, 58%) !important;
  height: 90px !important;
  width: 260px !important;
  color: #fff !important;
  overflow: hidden !important;
}

.css-6e1xp8-MuiTypography-root,
.css-ekiw3 {
  font-size: 14px !important;
}

.rowStyle {
  display: flex !important;
  justify-content: center !important;
  padding: 0px 14px !important;
  align-items: center !important;
  overflow: hidden !important;
}

.css-i315ha-MuiTypography-root,
.css-dg924h {
  font-size: 13px !important;
}

.bgDrivers {
  background-color: #bbbcbf !important;
  color: #fff !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
}
.css-s18byi {
  background-color: transparent !important;
  box-shadow: none !important;
}
.menuItem {
  font-weight: 500 !important;
}
.css-8r01yy-MuiList-root-RaMenu-root,
.css-h8b3x4 {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.css-io13u7-RaLayout-root .RaLayout-content,
.css-2scfwh .RaLayout-content {
  margin: 10px !important;
}

.css-13z9mp5-MuiButtonBase-root-MuiButton-root:hover,
.css-1ic30m4:hover {
  background-color: transparent !important;
}

.appBar {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 20px !important;
}

.css-7ma96y-MuiPaper-root-MuiAppBar-root,
.css-1nw88iw {
  width: 78% !important;
  right: 300px !important;
  left: 300px !important;
  /* top: 20px !important; */
  top: 0px !important;
  /* box-shadow: 0px 10px 30px #ffa01470 !important; */
  /* height: 60px !important; */
}
.css-hyum1k-MuiToolbar-root,
.css-i6s8oy {
  min-height: 60px !important;
}

.css-1a69w1n-MuiStack-root,
.css-1lxwves {
  margin-top: 30px !important;
}
.css-j204z7-MuiFormControlLabel-root,
.css-1jaw3da {
  flex-direction: row-reverse !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.css-julti5-MuiSwitch-root,
.css-ecvcn9 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}
.css-6e1xp8-MuiTypography-root,
.css-1prq6g1 {
  font-weight: bolder !important;
  font-size: 19px !important;
  color: #000 !important;
}

.campaignViewHeader {
  background-color: #fff !important;
  color: #b1aaaa !important;
  border: none !important;
}

.sliceBox {
  width: 171px;
  height: 70px;
  background: transparent linear-gradient(143deg, #ffffff 60%, #efbf77 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 24px #8da7ff29;
  border-radius: 10px;
  opacity: 1;
}

.css-1h6be2v-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-13pk724.Mui-selected {
  color: #fff !important;
}

.MuiTypography-h5,
.css-1ga4hkn-MuiTypography-root {
  text-transform: capitalize !important;
  font-weight: bolder !important;
  font-size: 1.1rem !important;
}

.css-rdlif7-MuiTypography-root,
.css-kn4rmc {
  color: #777676 !important;
}

.css-13pk724.Mui-selected,
.css-1h6be2v-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #ffa92b !important;
}
